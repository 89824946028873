<template>
  <div class="align-items-center d-flex" v-if="extraRow > 0">
    <div class="progress w-4rem">
      <div
        class="progress-bar"
        role="progressbar"
        :style="`width: ${Math.round((modelValue / extraRow) * 100)}%`"
      ></div>
    </div>
    <span class="ms-3 min-w-3rem">
      {{ Math.round(modelValue * 100) / 100 }} /
      {{ Math.round(extraRow * 100) / 100 }}
    </span>
  </div>
</template>
<script>
export default {
  name: "Percent",
  props: {
    id: Number,
    row: Object,
    modelValue: Number,
    extraRow: {
      default() {
        return 1;
      },
      type: Number,
    },
  },
  data() {
    return {};
  },

  methods: {},
};
</script>
